const initialState = "";

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case "REQUEST":
      return {
        ...state,
        isLoggedIn: Boolean(action.payload),
      };

    default:
      return state;
  }
}
