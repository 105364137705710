import * as React from "react";
import PropTypes from "prop-types";

export default function Container({ children }) {
  return (
    <div className="container-md col col-sm-12 col-md-8 col-lg-6">
      {children}
    </div>
  );
}

Container.propTypes = {
  Layout: PropTypes.node,
};
