import * as React from "react";

export default function ActionButton({ text, isDelete, linkTo, onClick }) {
  return (
    <button
      type="button"
      href="/"
      className={`btn btn-${
        isDelete ? "danger" : "primary"
      } btn-sm text-uppercase ${isDelete ? "mb-5" : null}`}
      onClick={onClick}
    >
      {text}
    </button>
  );
}
