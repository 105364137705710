import * as React from "react";
import PropTypes from "prop-types";
import Header from "./header";
import Footer from "./footer";
import Container from "./components/container";

export default function Layout({ children }) {
  return (
    <>
      <Header />
      <main>
        <Container>{children}</Container>
      </main>
      <Footer />
    </>
  );
}

Layout.propTypes = {
  Layout: PropTypes.node,
};
