import * as React from "react";
import PropTypes from "prop-types";

export default function Avatar({ gender, userName, isPoster }) {
  return (
    <img
      className="img-fluid m-2"
      src={`/img/${gender ? "male" : "female"}.png`}
      alt={userName}
      width={isPoster ? "90" : "35"}
      style={{ borderRadius: isPoster ? "5%" : "50%" }}
    />
  );
}

Avatar.propTypes = {
  gender: PropTypes.string,
  userName: PropTypes.string,
};

Avatar.defaultProps = {
  gender: null,
  userName: null,
};
