import * as React from "react";
import Container from "./components/container";

export default function Footer() {
  return (
    <footer className="navbar navbar-light bg-light border-top mt-4">
      <Container>
        <div className="text-muted p-4 m-auto">
          &copy; 2021 ConcertCare. All rights reserved.
        </div>
      </Container>
    </footer>
  );
}
