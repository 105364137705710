import * as React from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import SelectOption from "../components/select-option";
import TextInput from "../components/text-input";
import action from "../redux/action";

export default function NewPost() {
  // Name
  const [name, setName] = React.useState("");
  const onNameChange = (event) => setName(event.target.value);

  // Email
  const [email, setEmail] = React.useState("");
  const onEmailChange = (event) => setEmail(event.target.value);

  // Gender
  const [gender, setGender] = React.useState("male");
  const onGenderChange = (event) => setGender(event.currentTarget.value);

  // Status
  const [status, setStatus] = React.useState("active");
  const onStatusChange = (event) => setStatus(event.currentTarget.value);

  // Title
  const [title, setTitle] = React.useState("");
  const onTitleChange = (event) => setTitle(event.target.value);

  // Body
  const [body, setBody] = React.useState("");
  const onBodyChange = (event) => setBody(event.target.value);

  // User ID
  const [userId, setUserId] = React.useState("");

  // Response Status Code
  const [resCode, setResCode] = React.useState("");

  // For Axios
  const config = {
    headers: {
      Authorization:
        "Bearer 9f89493523016832b6ff36c0ab379827c28d17e12ebe06c5b4eebbf9190a6325",
    },
  };

  const handleNext = () =>
    axios
      .post(
        "https://gorest.co.in/public/v1/users",
        { name, email, gender, status },
        config
      )
      .then((res) => {
        setResCode(res.status);
        setUserId(res.data.data.id);
      })
      .catch((err) => setResCode(err.response.status))
      .finally(() => setTimeout(() => setResCode(null), 4000));

  const dispatch = useDispatch();
  const isLoggedIn = useSelector((state) => state.isLoggedIn);

  React.useEffect(() => {
    dispatch(action(userId));
  }, [dispatch, userId]);

  const handlePost = () =>
    axios
      .post(
        `https://gorest.co.in/public/v1/users/${userId}/posts`,
        { title, body, user: { userId } },
        config
      )
      .then((res) => setResCode(res.status))
      .catch((err) => setResCode(err.response.status))
      .finally(() => setTimeout(() => setResCode(null), 4000));

  const PostForm = (
    <>
      <TextInput inputText="Title" value={title} onChange={onTitleChange} />
      <div className="mb-3">
        <label for="exampleFormControlTextarea1" className="form-label">
          Body
        </label>
        <textarea
          className="form-control"
          id="PostBody"
          rows="3"
          value={body}
          onChange={onBodyChange}
        ></textarea>
      </div>
    </>
  );

  const UserForm = (
    <>
      <TextInput inputText="Name" value={name} onChange={onNameChange} />
      <TextInput inputText="Email" value={email} onChange={onEmailChange} />
      <SelectOption
        selectLabel="Gender"
        value={gender}
        onChange={onGenderChange}
      />
      <SelectOption
        selectLabel="Status"
        value={status}
        onChange={onStatusChange}
      />
    </>
  );

  const InfoBox = (resCode) => {
    return (
      <div
        className={`alert alert-${resCode >= 400 ? "danger" : "success"} mt-4`}
        role="alert"
      >
        {resCode >= 400 ? "Request Failed" : "Success"}
      </div>
    );
  };

  return (
    <>
      {resCode ? InfoBox(resCode) : null}
      {isLoggedIn ? PostForm : UserForm}
      <div className="d-grid">
        <button
          className="btn btn-primary mt-4"
          type="button"
          onClick={userId ? handlePost : handleNext}
          disabled={Boolean(resCode)}
        >
          {isLoggedIn ? "Post" : "Next"}
        </button>
      </div>
    </>
  );
}
