import * as React from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import Avatar from "../components/avatar";
import Pagination from "../components/pagination";
import SpecialName from "../components/special-name";

export default function Home() {
  const [data, setData] = React.useState([]);
  const [pageNumber, setPageNumber] = React.useState(1);

  const handlePrevious = () => setPageNumber(pageNumber - 1);
  const handleNext = () => setPageNumber(pageNumber + 1);

  React.useEffect(() => {
    axios
      .get(`https://gorest.co.in/public/v1/posts?page=${pageNumber}`)
      .then((res) => setData(res.data.data))
      .catch((err) => console.log("ERR", err));
  }, [pageNumber]);

  return (
    <>
      <div className="row">
        {data.map((el) => (
          <div className="col-sm-12 col-md-6 mt-4" key={el.id}>
            <div className="card">
              <div className="card-body">
                <Link className="text-decoration-none mt-3" to={`/${el.id}`}>
                  <h5 className="card-title fw-bold text-dark">{el.title}</h5>
                </Link>
                <div className="position-relative">
                  <Avatar />
                  <SpecialName userId={el.user_id} />
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <Pagination handlePrevious={handlePrevious} handleNext={handleNext} />
    </>
  );
}
