import * as React from "react";

export default function SelectOption({ selectLabel, value, onChange }) {
  return (
    <div className="mb-4 mt-4">
      <label for="FormControlInput" className="form-label">
        {selectLabel}
      </label>
      <select
        className="form-select"
        aria-label={`Select ${selectLabel}`}
        value={value}
        onChange={onChange}
      >
        <option selected value={selectLabel === "Gender" ? "male" : "active"}>
          {selectLabel === "Gender" ? "Male" : "Active"}
        </option>
        <option value={selectLabel === "Gender" ? "female" : "inactive"}>
          {selectLabel === "Gender" ? "Female" : "Inactive"}
        </option>
      </select>
    </div>
  );
}
