import axios from "axios";
import * as React from "react";

export default function SpecialName({ userId }) {
  const [name, setName] = React.useState("");

  React.useEffect(() => {
    if (userId) {
      axios
        .get(`https://gorest.co.in/public/v1/users/${userId}`)
        .then((res) => setName(res.data.data.name))
        .catch((err) => console.log(err));
    }
  }, [userId]);

  return (
    <a
      href={`/user/${userId}`}
      className="h6 stretched-link text-decoration-none"
    >
      {name}
    </a>
  );
}
