import * as React from "react";
import { Link } from "react-router-dom";
import Container from "./components/container";

export default function Header() {
  return (
    <header className="navbar navbar-dark bg-dark">
      <Container>
        <Link to="/">
          <img src="/logo.png" alt="ConcertCare" height="35" />
        </Link>
        <Link to="/new">
          <button className="btn btn-primary btn-sm" type="button">
            New Post
          </button>
        </Link>
      </Container>
    </header>
  );
}
