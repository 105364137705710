import * as React from "react";

export default function TextInput({ inputText, value, onChange }) {
  return (
    <div className="mb-4 mt-4">
      <label for="FormControlInput" className="form-label">
        {inputText}
      </label>
      <input
        type={inputText === "Email" ? "email" : "text"}
        className="form-control"
        id={inputText}
        value={value}
        onChange={onChange}
      />
    </div>
  );
}
