import * as React from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import Avatar from "../components/avatar";

export default function UserProfile() {
  const { id } = useParams();

  const [post, setPost] = React.useState([]);
  const [user, setUser] = React.useState([]);

  React.useEffect(() => {
    axios
      .get(`https://gorest.co.in/public/v1/users/${id}/posts`)
      .then((res) => setPost(res.data.data))
      .catch((err) => console.log("ERR", err));

    axios
      .get(`https://gorest.co.in/public/v1/users/${id}`)
      .then((res) => setUser(res.data.data))
      .catch((err) => console.log(err));
  }, [id]);

  return (
    <>
      <div className="d-flex border-bottom mb-2 pt-4 pb-4">
        <Avatar isPoster gender={user.gender} />
        <div className="align-self-center">
          <div className="fw-bold">{user.name}</div>
          <div>{user.email}</div>
          <div
            className={`badge ${
              user.status === "active" ? "bg-success" : "bg-danger"
            }`}
          >
            {user.status}
          </div>
        </div>
      </div>
      <div class="row">
        {post.map((el) => (
          <div className="col-sm-12 col-md-6 mt-4" key={el.id}>
            <div className="card">
              <div className="card-body">
                <Link className="text-decoration-none mt-3" to={`/${el.id}`}>
                  <h5 className="card-title fw-bold text-dark">{el.title}</h5>
                </Link>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
}
