import * as React from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import ActionButton from "../components/action-button";
import Avatar from "../components/avatar";
import Name from "../components/name";

export default function Post() {
  const { id } = useParams();
  const [data, setData] = React.useState([]);
  const [user, setUser] = React.useState([]);
  const [deleted, setDeleted] = React.useState(false);

  // For Post Data
  React.useEffect(() => {
    axios
      .get(`https://gorest.co.in/public/v1/posts/${id}`)
      .then((res) => setData(res.data.data))
      .then(() => fetchUser(data.user_id))
      .catch((err) => console.log("ERR"));
  }, [id, data.user_id]);

  // For User Data
  const fetchUser = (id) => {
    if (id) {
      axios
        .get(`https://gorest.co.in/public/v1/users/${id}`)
        .then((res) => setUser(res.data.data))
        .catch((err) => console.log("ERR"));
    }
  };

  // Delete Post
  const handleDelete = () =>
    axios
      .delete(`https://gorest.co.in/public/v1/posts/${id}`, {
        headers: {
          Authorization:
            "Bearer 9f89493523016832b6ff36c0ab379827c28d17e12ebe06c5b4eebbf9190a6325",
        },
      })
      .then(setDeleted(true))
      .catch(console.log("NOTOKAY"));

  return !deleted ? (
    <>
      <h1 className="h4 fw-bold text-center mt-5">{data.title}</h1>
      <div className="blockquote-footer text-center position-relative m-3">
        <Avatar gender={user.gender} userName={user.name} />
        <Name userId={user.id} userName={user.name} />
      </div>
      <div className="mt-5 mb-5">{data.body}</div>
      <ActionButton text="Delete" isDelete onClick={handleDelete} />
    </>
  ) : (
    <div className="alert alert-success mt-4" role="alert">
      Deleted
    </div>
  );
}
