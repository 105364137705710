import * as React from "react";
import { Switch, Route } from "react-router-dom";
import Layout from "./layout";
import Home from "./pages/home";
import NewPost from "./pages/new-post";
import Post from "./pages/post";
import UserProfile from "./pages/user-profile";

export default function App() {
  return (
    <Layout>
      <Switch>
        <Route exact path="/new" component={NewPost} />
        <Route exact path="/user/:id" component={UserProfile} />
        <Route path="/:id" component={Post} />
        <Route exact path="/" component={Home} />
      </Switch>
    </Layout>
  );
}
