import * as React from "react";

export default function ComponentPagination({ handleNext, handlePrevious }) {
  return (
    <div
      className="btn-toolbar justify-content-between mt-5 mb-5"
      role="toolbar"
    >
      <div className="d-grid gap-2 col-4">
        <button
          className="btn btn-primary"
          type="button"
          onClick={handlePrevious}
        >
          Previous
        </button>
      </div>
      <div className="d-grid gap-2 col-4">
        <button className="btn btn-primary" type="button" onClick={handleNext}>
          Next
        </button>
      </div>
    </div>
  );
}
