import * as React from "react";
import PropTypes from "prop-types";

export default function Name({ userId, userName }) {
  return (
    <a
      href={`/user/${userId}`}
      className="h6 stretched-link text-decoration-none"
    >
      {userName}
    </a>
  );
}

Name.propTypes = {
  userId: PropTypes.number,
  userName: PropTypes.string,
};

Name.defaultProps = {
  userId: null,
  userName: null,
};
